<template>
  <div class="rentPlaceDetail">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="所属地区"
        :rules="[
          { required: true, message: '请选择所属地区', trigger: 'blur' },
        ]"
        prop="typeName"
      >
        <v-select
          clearable
          disabled
          :options="areaList"
          v-model="form.typeName"
        />
      </el-form-item>
      <el-form-item
        label="房号"
        :rules="[{ required: true, message: '请输入房号', trigger: 'blur' }]"
        prop="name"
      >
        <v-input
          placeholder="请输入房号"
          v-model="form.name"
          disabled
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="租赁状态"
        :rules="[
          { required: true, message: '请选择租赁状态', trigger: 'blur' },
        ]"
        prop="rent"
      >
        <v-select clearable :options="rentStatusList" v-model="form.rent" />
      </el-form-item>
      <el-form-item
        v-if="form.rent == '已出租'"
        label="租用人姓名"
        :rules="[
          { required: false, message: '请填写联系人姓名', trigger: 'blur' },
        ]"
        prop="renterName"
      >
        <v-input
          placeholder="请填写租用人姓名"
          v-model="form.renterName"
        ></v-input>
      </el-form-item>
      <el-form-item
        v-if="form.rent == '已出租'"
        label="租用人手机"
        :rules="[
          { required: false, message: '请输入租用人手机', trigger: 'change' },
          { validator: validatePhone, trigger: 'change' },
        ]"
        prop="renterMobile"
      >
        <v-input
          placeholder="请输入租用人手机"
          v-model="form.renterMobile"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="面积"
        :rules="[{ required: true, message: '请输入面积', trigger: 'blur' }]"
        prop="size"
      >
        <v-input
          placeholder="请输入面积"
          v-model="form.size"
          :width="250"
        ></v-input>
        <span class="infoLabel">m2</span>
      </el-form-item>
      <el-form-item
        label="朝向"
        :rules="[{ required: true, message: '请选择朝向', trigger: 'blur' }]"
        prop="direction"
      >
        <v-select clearable :options="directionList" v-model="form.direction" />
      </el-form-item>
      <el-form-item
        label="月单价"
        :rules="[{ required: true, message: '请输入月单价', trigger: 'blur' }]"
        prop="monthPrice"
      >
        <v-input
          placeholder="请输入月单价"
          v-model="form.monthPrice"
          type="number"
        ></v-input>
        <span class="infoLabel">元/月</span>
      </el-form-item>
      <el-form-item
        label="日单价"
        :rules="[{ required: true, message: '请输入日单价', trigger: 'blur' }]"
        prop="dayPrice"
      >
        <v-input
          placeholder="请输入日单价"
          v-model="form.dayPrice"
          type="number"
        ></v-input>
        <span class="infoLabel">元/日</span>
      </el-form-item>
      <el-form-item
        label="时单价"
        :rules="[{ required: true, message: '请输入时单价', trigger: 'blur' }]"
        prop="hourPrice"
      >
        <v-input
          placeholder="请输入时单价"
          v-model="form.hourPrice"
          type="number"
        ></v-input>
        <span class="infoLabel">元/时</span>
      </el-form-item>
      <el-form-item
        label="物业费"
        :rules="[{ required: true, message: '请输入物业费', trigger: 'blur' }]"
        prop="propertyPrice"
      >
        <v-input
          placeholder="请输入物业费"
          v-model="form.propertyPrice"
          type="number"
        ></v-input>
        <span class="infoLabel">元/m2/月</span>
      </el-form-item>
      <el-form-item
        label="公共耗能费"
        :rules="[
          { required: true, message: '请输入公共耗能费', trigger: 'blur' },
        ]"
        prop="publicPrice"
      >
        <v-input
          placeholder="请输入公共耗能费"
          v-model="form.publicPrice"
          type="number"
        ></v-input>
        <span class="infoLabel">元/m2/月</span>
      </el-form-item>

      <el-form-item
        label="描述"
        :rules="[{ required: true, message: '请填写描述', trigger: 'blur' }]"
        prop="description"
      >
        <v-input
          placeholder="请填写描述"
          v-model="form.description"
          type="textarea"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="图片"
        :rules="[{ required: true, message: '请上传图片', trigger: 'blur' }]"
        prop="picture"
      >
        <v-upload :limit="4" :imgUrls.sync="form.picture" />
      </el-form-item>
      <el-form-item
        label="联系人姓名"
        :rules="[
          { required: true, message: '请填写联系人姓名', trigger: 'blur' },
        ]"
        prop="contactName"
      >
        <v-input
          placeholder="请填写联系人姓名"
          v-model="form.contactName"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="联系人手机"
        :rules="[
          { required: true, message: '请输入联系人手机', trigger: 'change' },
          { validator: validatePhone, trigger: 'change' },
        ]"
        prop="contactMobile"
      >
        <v-input
          placeholder="请输入联系人手机"
          v-model="form.contactMobile"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="联系人二维码"
        :rules="[
          { required: true, message: '请上传联系人二维码', trigger: 'blur' },
        ]"
        prop="contactWechatQrcode"
      >
        <v-upload :limit="1" :imgUrls.sync="form.contactWechatQrcode" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { mobileRegular } from "@/utils/regular";
import { addRentUrl, getRentDetailUrl, getSearchListUrl } from "./api";

export default {
  name: "goodsEdit",
  data() {
    return {
      submitConfig: {
        queryMethodTe: true,
        queryMethod: "post",
        queryUrl: getRentDetailUrl,
        submitUrl: addRentUrl,
      },
      directionList: [],
      areaCountList: [],
      areaList: [],
      rentStatusList: [],
      form: {
        spaceId: "",
        typeName: "",
        name: "",
        rent: "",
        size: "",
        direction: "",
        monthPrice: "",
        dayPrice: "",
        hourPrice: "",
        propertyPrice: "",
        publicPrice: "",
        description: "",
        picture: "",
        contactName: "",
        contactMobile: "",
        contactWechatQrcode: "",
        renterMobile: "",
        renterName: "",
      },
    };
  },
  mounted() {
    const { id, communityId } = this.$route.query;
    this.form.spaceId = id;
    this.getSearchInfo();
    if (id !== undefined) {
      this.$refs.formPanel.getData({ spaceId: id });
    } else {
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    validatePhone(rule, value, callback) {
      let regExp = mobileRegular;
      if (!regExp.test(value)) {
        callback(new Error("您填写手机号码格式不正确"));
      } else {
        callback();
      }
    },
    getSearchInfo() {
      let params = {};
      this.$axios.get(`${getSearchListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.directionList) {
            res.data.directionList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.directionList.push(item);
            });
          }
          if (res.data && res.data.rentStatusList) {
            res.data.rentStatusList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.rentStatusList.push(item);
            });
          }
          if (res.data && res.data.rentSpaceTypeList) {
            res.data.rentSpaceTypeList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.areaList.push(item);
            });
          }
          if (res.data && res.data.roomSizeList) {
            res.data.roomSizeList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.areaCountList.push(item);
            });
          }
        }
      });
    },
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      let _this = this;
      this.form = [
        {
          spaceId: _this.form.spaceId,
          extra: {
            rent: _this.form.rent,
            renterName: _this.form.renterName,
            renterMobile: _this.form.renterMobile,
            size: _this.form.size,
            direction: _this.form.direction,
            monthPrice: _this.form.monthPrice,
            dayPrice: _this.form.dayPrice,
            hourPrice: _this.form.hourPrice,
            propertyPrice: _this.form.propertyPrice,
            publicPrice: _this.form.publicPrice,
            description: _this.form.description,
            picture: _this.form.picture,
            contactName: _this.form.contactName,
            contactMobile: _this.form.contactMobile,
            contactWechatQrcode: _this.form.contactWechatQrcode,
          },
        },
      ];
      return true;
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.rentPlaceDetail {
  box-sizing: border-box;
  height: 100%;
  .infoLabel {
    margin-left: 20px;
  }
}
</style>
<style lang="less">
.rentPlaceDetail {
}
</style>
